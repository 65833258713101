var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',{attrs:{"show-title":_vm.showTitle,"title":_vm.modal.title,"min-width":_vm.modal.minWidth,"width":_vm.modal.width,"min-height":_vm.modal.minHeight,"height":_vm.modal.height,"drag-enabled":_vm.modal.dragEnabled,"resize-enabled":_vm.modal.resizeEnabled,"show-close-button":_vm.modal.showCloseButton,"hide-on-outside-click":_vm.modal.hideOnOutsideClick,"visible":_vm.isOpen},on:{"hiding":_vm.closeModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"grid grid-cols-1 gap-x-8"},[_c('esp-dx-data-grid',{ref:_vm.dataGrid.refName,attrs:{"data-grid":_vm.dataGrid},on:{"init-new-row":_vm.onInitNewRow}})],1)]},proxy:true}])},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
					width: '120',
					height: '40',
					onClick: this.closeModal,
				}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }