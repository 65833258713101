<!--
  PACKAGE_NAME : src/pages/cc/ivr/dnis/modal-setting.vue
  FILE_NAME : modal-notice
  AUTHOR : hmlee
  DATE : 2024-09-04
  DESCRIPTION : 환경설정 설정 모달
-->
<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <div class="grid grid-cols-1 gap-x-8">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="onInitNewRow"></esp-dx-data-grid>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
					width: '120',
					height: '40',
					onClick: this.closeModal,
				}"
    />
  </DxPopup>
</template>

<script>
import {DxPopup, DxPosition, DxToolbarItem} from 'devextreme-vue/popup';
import {DxDataGrid, DxColumn, DxSelection, DxScrolling, DxPaging, DxPager} from 'devextreme-vue/data-grid';

import {DxTextBox} from 'devextreme-vue/text-box';
import {DxButton} from 'devextreme-vue/button';
import {DxSelectBox} from 'devextreme-vue/select-box';

import {isSuccess, cloneObj} from "@/plugins/common-lib";
import {DxValidationGroup} from "devextreme-vue/validation-group";
import {DxRequiredRule, DxValidator} from "devextreme-vue/validator";
import {DxNumberBox} from "devextreme-vue/number-box";
import DxSwitch from "devextreme-vue/switch";
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

let vm = null;
export default {
  components: {
    EspDxDataGrid,
    DxSwitch, DxValidator, DxNumberBox, DxRequiredRule, DxValidationGroup,
    DxPopup, DxPosition, DxToolbarItem,
    DxDataGridx: DxDataGrid,
    DxColumn,
    DxSelection,
    DxScrolling,
    DxPaging,
    DxPager,

    DxTextBox,
    DxButton,
    DxSelectBox,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    settingData: {
      default: null,
      type: Object,
    },
  },
  watch: {},
  computed: {
    /** @description: 모달 타이틀 출력 여부 */
    showTitle() {
      return !!this.modal.title;
    },
  },
  data() {
    return {
      modal: {
        title: this.$_msgContents('CC.WORD.CONFIG_SETTINGS', { defaultValue: '환경설정' }),
        minWidth: '300',
        width: '800',
        minHeight: '300',
        height: '500',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
      },
      config: {
        stylingMode: 'outlined',    //[outlined, filled, underlined]
        showClearButton: true,
        width: '100%',
        noticeList: [],
      },
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'settingGrid',
        keyExpr: 'id',
        allowColumnResizing: true, //컬럼 사이즈 허용
        allowReordering: true, //inline속성 느낌
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        dataSource: [],
        height: '350',    // 주석처리시 100%
        apiActionNm: {
          //select: 'CC_IVR_DNIS_SETTING_LIST',
          merge: 'CC_IVR_DNIS_SETTING_MERGE',
          delete: 'CC_IVR_DNIS_SETTING_DELETE',
        },
        customEvent: {
          initNewRow: true,
        },
        showActionButtons: {
          select: false,
          update: true,
          delete: true,
          copy: false,
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: false,
          expandMode: 'rowClick' // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple' // single multiple
        },
        scrolling: { // 미사용시 주석처리
          mode: 'standard' //스크롤 모드 : ['infinite', 'standard', 'virtual']
        },
        remoteOperations: { // 서버사이드 여부
          filtering: true,
          sorting: true,
          grouping: true,
          paging: true,
        },
        paging: { // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0 // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: "compact", //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: 'dnisId',
            dataField: 'dnisId',
            alignment: 'center',
            visible: false,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '키',
            i18n: 'CC.WORD.KEY',
            dataField: 'key',
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            validationRules: [
              {
                type: 'required',
                message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
              },
            ],
          },
          {
            caption: '값',
            i18n: 'CC.WORD.VALUE',
            dataField: 'value',
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            validationRules: [
              {
                type: 'required',
                message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
              },
            ],
          },
          {
            caption: '설명',
            i18n: 'CC.WORD.DESCRIPTION',
            dataField: 'description',
            alignment: 'center',
            width: '50%',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            alignment: 'center',
            visible: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
        ]
      },
    }
  },
  methods: {
    /** @description: 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },
    /** @description: 그리드 행 추가시 초기 설정 */
    onInitNewRow(e) {
      e.data.dnisId = this.settingData.id;
      e.data.viewFl = 'Y';
    },
    /** @description: 그리드 데이터 조회 */
    async selectDataList(sort = '+id') {
      let vm = this;

      this.dataGrid.dataSource = new CustomStore({
        key: 'id',
        async load(loadOptions) {
          let params = vm.$_getDxDataGridParam(loadOptions);

          if (!params.sort)
            params.sort = sort;

          //dnis
          params.dnisId = vm.settingData.id;

          const payload = {
            actionname: 'CC_IVR_DNIS_SETTING_LIST',
            data: params,
            useErrorPopup: true,
          }

          const res = await vm.CALL_CC_API(payload);
          let rtnData = {
            data: [],
            totalCount: 0,
          }
          if (isSuccess(res)) {
            rtnData = {
              data: res.data.data,
              totalCount: res.data.header.totalCount,
            }
            vm.$refs[vm.dataGrid.refName].totalCount = rtnData.totalCount;
          }
          return rtnData;
        }
      });

    },
    /** @description: 라이프사이클 created시 호출되는 메서드 */
    createdData() {
      this.selectDataList();
    },
    /** @description: 라이프사이클 mounted시 호출되는 메서드 */
    mountedData() {

    },
    /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
    destroyedData() {

    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
  destroyed() {
    this.destroyedData();
    //this.$off('input');
  },
}
</script>