<!--
  PACKAGE_NAME : src\pages\op\ivr\dnis\list.vue
  FILE_NAME : list
  AUTHOR : hmlee
  DATE : 2024-05-22
  DESCRIPTION : 대표번호 관리 리스트
-->
<template>
  <div>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="onInitNewRow" />
      </div>
    </div>

    <!-- 공지사항 설정 -->
    <ModalNotice
      v-if="modal.notice.visible"
      :is-open="modal.notice.visible"
      :notice-data="modal.notice.data"
      @saveModal="onSaveModal('notice')"
      @closeModal="onCloseModal('notice')"
    />

    <!-- 인입그룹 설정 -->
    <ModalIbg
      v-if="modal.ibg.visible"
      :is-open="modal.ibg.visible"
      :ibg-data="modal.ibg.data"
      @saveModal="onSaveModal('ibg')"
      @closeModal="onCloseModal('ibg')"
    />

    <!-- 환경설정 설정 -->
    <ModalSetting
      v-if="modal.setting.visible"
      :is-open="modal.setting.visible"
      :setting-data="modal.setting.data"
      @saveModal="onSaveModal('setting')"
      @closeModal="onCloseModal('setting')"
    />
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { formatDate, isSuccess } from '@/plugins/common-lib';
  import ModalNotice from './modal-notice.vue';
  import ModalIbg from './modal-ibg.vue';
  import ModalSetting from './modal-setting.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import CustomStore from "devextreme/data/custom_store";

  export default {
    components: {
      EspDxDataGrid,
      ModalNotice,
      ModalIbg,
      ModalSetting,
      DxButton,
    },
    props: {},
    watch: {},
    data() {
      return {
        config: {
          ivrSvctpList: [], // 서비스코드 템플릿 리스트
          scheduleList: [], // 운영스케줄 리스트
        },
        modal: {
          notice: {
            visible: false,
            data: {},
          },
          ibg: {
            visible: false,
            data: {},
          },
          setting: {
            visible: false,
            data: {},
          },
        },
        dataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'ivrDnisGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSourceDefaultSortColumn: '+ivrDnisOrd',
          dataSource: [],
          apiActionNm: {
            merge: 'CC_IVR_DNIS_MERGE',
            delete: 'CC_IVR_DNIS_DELETE',
          },
          customEvent: {
            initNewRow: true,
            reorder: false,
          },
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            select: false,
            update: true,
            delete: true,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          dragging: {
            sortColumn: 'ivrDnisOrd',
            allowReordering: false,
            dropFeedbackMode: 'push',
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            filtering: true,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true,
            allowDeleting: false,
            allowAdding: true,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사이트',
              i18n: 'COMMON.WORD.SITE',
              dataField: 'siteId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
              validationRules: [
                {
                  type: 'required',
                  message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: '센터',
              i18n: 'COMMON.WORD.TENANT',
              dataField: 'tenantId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
              validationRules: [
                {
                  type: 'required',
                  message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: 'DNIS',
              dataField: 'dnis',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              validationRules: [
                {
                  type: 'required',
                  message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: 'DNIS명',
              i18n: 'CC.WORD.DNIS_TITLE',
              dataField: 'dnisNm',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              validationRules: [
                {
                  type: 'required',
                  message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: '운영스케줄',
              i18n: 'CC.WORD.OP_SCHEDULE',
              dataField: 'scheduleId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: [],
                displayExpr: 'scheduleNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '서비스코드',
              i18n: 'CC.WORD.SERVICECODE',
              dataField: 'svctpId',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: [],
                displayExpr: 'svctpNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '공지사항',
              i18n: 'CC.WORD.NOTICE',
              dataField: 'noticeNm',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                if (options.value) {
                  let aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    this.onOpenModal('notice', options.data); //공지사항 설정 팝업 오픈
                  });
                  container.append(aTag);
                } else {
                  let button = new DxButton({
                    propsData: {
                      text: this.$_msgContents('COMPONENTS.SETTING', { defaultValue: '설정' }),
                      elementAttr: { class: 'btn_XS white light_filled' },
                      width: 60,
                      height: 30,
                      value: options.data.id,
                      onClick: () => {
                        this.onOpenModal('notice', options.data); //인입그룹 설정 팝업 오픈
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '인입그룹',
              i18n: 'CC.WORD.IBG',
              dataField: 'dnisIbgs',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateCellValue: (rowData) => {
                if (!rowData.dnisIbgs) return 0;
                return rowData.dnisIbgs.length;
              },
              cellTemplate: (container, options) => {
                if( options.value > 0 ){
                  let aTag = document.createElement('a');
                  aTag.innerText = `${options.value}개`;
                  aTag.addEventListener('click', () => {
                    this.onOpenModal('ibg', options.data); //인입그룹 설정 팝업 오픈
                  });

                  container.append(aTag);
                }else {
                  let button = new DxButton({
                    propsData: {
                      text: this.$_msgContents('COMPONENTS.SETTING', { defaultValue: '설정' }),
                      elementAttr: { class: 'btn_XS white light_filled' },
                      width: 60,
                      height: 30,
                      value: options.data.id,
                      onClick: () => {
                        this.onOpenModal('ibg', options.data); //인입그룹 설정 팝업 오픈
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '환경설정',
              i18n: 'CC.WORD.CONFIG_SETTING',
              dataField: 'dnisSettings',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateCellValue: (rowData) => {
                if (!rowData.dnisSettings) return 0;
                return rowData.dnisSettings.length;
              },
              cellTemplate: (container, options) => {
                if( options.value > 0 ){
                  //링크 클릭시 팝업
                  let aTag = document.createElement('a');
                  aTag.innerText = `${options.value}개`;
                  aTag.addEventListener('click', () => {
                    this.onOpenModal('setting', options.data); //환경설정 설정 팝업 오픈
                  });

                  container.append(aTag);
                }else {
                  let button = new DxButton({
                    propsData: {
                      text: this.$_msgContents('COMPONENTS.SETTING', { defaultValue: '설정' }),
                      elementAttr: { class: 'btn_XS white light_filled' },
                      width: 60,
                      height: 30,
                      value: options.data.id,
                      onClick: () => {
                        this.onOpenModal('setting', options.data); //환경설정 설정 팝업 오픈
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'ivrDnisOrd',
              alignment: 'center',
              visible: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '수정자',
              i18n: 'COMPONENTS.MODIFIER',
              dataField: 'editId',
              alignment: 'center',
              visible: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '수정일시',
              i18n: 'COMPONENTS.MODIFY_DATE_TIME',
              dataField: 'editDt',
              alignment: 'center',
              visible: false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatEditDt,
            },
            {
              caption: '등록자',
              i18n: 'COMPONENTS.REGISTRANT',
              dataField: 'regId',
              alignment: 'center',
              visible: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '등록일시',
              i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
              dataField: 'regDt',
              alignment: 'center',
              visible: false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatRegDt,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 팝업 열기
       * @param settingType 설정 타입(notice, ibg, setting)
       * @param settingData 모달로 넘길 설정 데이터 */
      onOpenModal(settingType, settingData) {
        this.modal[settingType].visible = true;
        this.modal[settingType].data = settingData;
      },
      /**
       * @description : 팝업 저장
       * @param settingType : 설정 타입(notice, ibg, setting)
       */
      onSaveModal(settingType) {
        this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        this.$refs[this.dataGrid.refName].refreshData();

        this.modal[settingType].visible = false;
        this.modal[settingType].data = {};
      },
      /**
       * @description : 팝업 닫기
       * @param settingType : 설정 타입(notice, ibg, setting)
       */
      onCloseModal(settingType) {
        this.modal[settingType].visible = false;
        this.modal[settingType].data = {};
      },
      /** @description: 그리드 행 추가시 초기 설정 */
      onInitNewRow(e) {
        e.data.viewFl = 'Y';
        e.data.ivrDnisOrd = this.$refs[this.dataGrid.refName].getItems.length + 1;
      },
      /** @description: 대표번호 리스트 조회 */
      async selectDataList(sort = '+ivrDnisOrd') {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            const payload = {
              actionname: 'CC_IVR_DNIS_LIST',
              data: params,
              useErrorPopup: true,
            };

            const res = await vm.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if(isSuccess(res)) {
              let dnisList = res.data.data;
              dnisList.forEach(dnis => {
                const dnisIbgs = vm.dnisIbgList.filter(dnisIbg => dnisIbg.dnisId === dnis.id);
                const dnisSettings = vm.dnisSettingList.filter(dnisSetting => dnisSetting.dnisId === dnis.id);
                if( dnisIbgs.length > 0 ) { //DNIS 인입그룹
                  dnis.dnisIbgs = dnisIbgs;
                }
                if( dnisSettings.length > 0 ) { //DNIS 환경설정
                  dnis.dnisSettings = dnisSettings;
                }
              });

              rtnData = {
                data: dnisList,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs[vm.dataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 운영스케줄 리스트 가져오기 */
      async selectScheduleList() {
        const payload = {
          actionname: 'CC_SCHEDULE_LIST',
          data: { sort: '+id' },
          useErrorPopup: true,
        };

        const res = await this.CALL_CC_API(payload);
        let rstData = [];
        if (isSuccess(res)) {
          rstData = res.data.data;
        }
        return rstData;
      },
      /** @description: 서비스코드 템플릿 리스트 가져오기 */
      async selectIvrSvctpList() {
        const payload = {
          actionname: 'CC_IVR_SVC_TP_LIST',
          data: { sort: '+svctpOrd', viewFl: 'Y' },
          useErrorPopup: true,
        };

        const res = await this.CALL_CC_API(payload);
        let rstData = [];
        if (isSuccess(res)) {
          rstData = res.data.data[0].content;
        }
        return rstData;
      },
      /** @description : DNIS 인입그룹 조회 메서드 */
      async selectDnisIbgList() {
        const payload = {
          actionname: 'CC_IVR_DNIS_IBG_LIST',
          useErrorPopup: true,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.dnisIbgList = res.data.data;
        }
      },
      /** @description : DNIS 환경설정 조회 메서드 */
      async selectDnisSettingList() {
        const payload = {
          actionname: 'CC_IVR_DNIS_SETTING_LIST',
          useErrorPopup: true,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.dnisSettingList = res.data.data;
        }
      },
      /** @description: 등록일시 데이터 출력 */
      formatRegDt(rowData) {
        if (rowData?.regDt) {
          return formatDate(rowData?.regDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description: 수정일시 데이터 출력 */
      formatEditDt(rowData) {
        if (rowData?.editDt) {
          return formatDate(rowData?.editDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description: 초기 데이터 조회 */
      async initData() {
        this.dataGrid.columns[4].lookup.dataSource = await this.selectScheduleList();
        this.dataGrid.columns[5].lookup.dataSource = await this.selectIvrSvctpList();
        await this.selectDnisIbgList();
        await this.selectDnisSettingList();
        await this.selectDataList();
      },
    },
    async created() {
      this.initData();
    },
  };
</script>
